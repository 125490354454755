<template>
    <v-form v-if="!completed" @submit.prevent="submit" v-model="isFormValid" lazy-validation>
        <p>{{ $t('forms.ForgotPasswordForm.description') }}</p>

        <div class="forgot-password-form">
            <v-alert type="error" v-if="notFound">
                {{ $t('forms.ForgotPasswordForm.not_found') }}
            </v-alert>

            <v-text-field
                ref="email_input"
                autofocus
                v-model="email"
                :label="$t('forms.ForgotPasswordForm.email.label')"
                :hint="$t('forms.ForgotPasswordForm.email.hint')"
                :placeholder="$t('forms.ForgotPasswordForm.email.placeholder')"
                :rules="rules"
                :disabled="loading"
            />

            <v-btn type="submit" color="success" block class="my-3" :disabled="!isFormValid" :loading="loading">{{ $t('actions.send') }}</v-btn>
            <v-btn :to="{ name: 'login' }" text block :disabled="loading">{{ $t('actions.cancel') }}</v-btn>
        </div>

    </v-form>
    <div v-else>
        <v-alert type="success">{{ $t('forms.ForgotPasswordForm.success') }}</v-alert>

        <v-btn :to="{ name: 'login' }" text block>{{ $t('actions.back') }}</v-btn>
    </div>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { required, email } from '@/utils/validation'

export default {
    name: 'ResetPasswordEmail',
    data: vm => ({
        loading: false,
        isFormValid: false,
        completed: false,
        notFound: false,
        email: '',
        rules: [required.and(email)],
    }),
    mixins: [ HasErrorHandlerMixin ],
    methods: {
        async submit() {
            this.notFound = false

            if (!this.isFormValid)
                return

            this.loading = true
            let res = await this.$axios.get('/admin/password-reset', { headers: { 'admin-email': this.email } })
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.completed = true
        },

        preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')

            if (status == 404) {
                this.notFound = true
                this.$nextTick(() => {
                    this.$refs.email_input.focus()
                })
                return
            }

            this.errorHandler(e)
        }
    },
}
</script>

<style scoped lang="scss">
.forgot-password-form {
    max-width: 300px;
    margin: auto;
}
</style>