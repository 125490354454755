var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.completed
    ? _c(
        "v-form",
        {
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("forms.ForgotPasswordForm.description")))
          ]),
          _c(
            "div",
            { staticClass: "forgot-password-form" },
            [
              _vm.notFound
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("forms.ForgotPasswordForm.not_found")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("v-text-field", {
                ref: "email_input",
                attrs: {
                  autofocus: "",
                  label: _vm.$t("forms.ForgotPasswordForm.email.label"),
                  hint: _vm.$t("forms.ForgotPasswordForm.email.hint"),
                  placeholder: _vm.$t(
                    "forms.ForgotPasswordForm.email.placeholder"
                  ),
                  rules: _vm.rules,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "my-3",
                  attrs: {
                    type: "submit",
                    color: "success",
                    block: "",
                    disabled: !_vm.isFormValid,
                    loading: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.send")))]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    to: { name: "login" },
                    text: "",
                    block: "",
                    disabled: _vm.loading
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
              )
            ],
            1
          )
        ]
      )
    : _c(
        "div",
        [
          _c("v-alert", { attrs: { type: "success" } }, [
            _vm._v(_vm._s(_vm.$t("forms.ForgotPasswordForm.success")))
          ]),
          _c(
            "v-btn",
            { attrs: { to: { name: "login" }, text: "", block: "" } },
            [_vm._v(_vm._s(_vm.$t("actions.back")))]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }